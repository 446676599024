import { graphql } from "gatsby";
import { InputsAutoSizing, InputsAutoSizingMore, InputsBasic, InputsColumnSizing, InputsDisabledForms, InputsDisabledFormsMore, InputsFileBrowser, InputsFeedback, InputsFormGrid, InputsFormGroups, InputsFormRow, InputsFormRowMore, InputsHelpText, InputsHorizontalForm, InputsInlineForms, InputsInlineText, InputsStates, InputsLabelSizing, InputsLarge, InputsReadonly, InputsReadOnlyPlainText, InputsTextArea, InputsTranslatingWithHtml, InputsTranslation } from "../../../../src/examples/components/Inputs";
import * as React from 'react';
export default {
  graphql,
  InputsAutoSizing,
  InputsAutoSizingMore,
  InputsBasic,
  InputsColumnSizing,
  InputsDisabledForms,
  InputsDisabledFormsMore,
  InputsFileBrowser,
  InputsFeedback,
  InputsFormGrid,
  InputsFormGroups,
  InputsFormRow,
  InputsFormRowMore,
  InputsHelpText,
  InputsHorizontalForm,
  InputsInlineForms,
  InputsInlineText,
  InputsStates,
  InputsLabelSizing,
  InputsLarge,
  InputsReadonly,
  InputsReadOnlyPlainText,
  InputsTextArea,
  InputsTranslatingWithHtml,
  InputsTranslation,
  React
};