import { graphql } from "gatsby";
import { TabsBasic, TabsSmall, TabsWithContent, TabsWithIcons } from "../../../../src/examples/components/Tabs";
import * as React from 'react';
export default {
  graphql,
  TabsBasic,
  TabsSmall,
  TabsWithContent,
  TabsWithIcons,
  React
};