import { graphql } from "gatsby";
import { ButtonsDanger, ButtonsDarkBackgrounds, ButtonsFullWidth, ButtonsIconsOnly, ButtonsLarge, ButtonsLight, ButtonsOutline, ButtonsSmall, ButtonsSolid, ButtonsTextAndIcons, ButtonsText, ButtonsYellow } from "../../../../src/examples/components/Buttons";
import * as React from 'react';
export default {
  graphql,
  ButtonsDanger,
  ButtonsDarkBackgrounds,
  ButtonsFullWidth,
  ButtonsIconsOnly,
  ButtonsLarge,
  ButtonsLight,
  ButtonsOutline,
  ButtonsSmall,
  ButtonsSolid,
  ButtonsTextAndIcons,
  ButtonsText,
  ButtonsYellow,
  React
};