import { graphql } from "gatsby";
import { BreadcrumbsBasic, BreadcrumbsWithIcons, BreadcrumbsAccessibleOptions, BreadcrumbsBehaviours } from "../../../../src/examples/components/Breadcrumbs";
import * as React from 'react';
export default {
  graphql,
  BreadcrumbsBasic,
  BreadcrumbsWithIcons,
  BreadcrumbsAccessibleOptions,
  BreadcrumbsBehaviours,
  React
};