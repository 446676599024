import { ModusLayoutScripts } from "../../../../src/common/ExternalDependencyHelper";
import { LayoutModus } from "../../../../src/examples/layout/Layout";
import routeImage from "../../../../src/assets/img/route.svg";
import checkMarkOrangeImage from "../../../../src/assets/img/check-mark-orange.png";
import * as React from 'react';
export default {
  ModusLayoutScripts,
  LayoutModus,
  routeImage,
  checkMarkOrangeImage,
  React
};