import { graphql } from "gatsby";
import { AccordionsBasic, AccordionsBorderless, AccordionsList, AccordionsSmall } from "../../../../src/examples/components/Accordions";
import * as React from 'react';
export default {
  graphql,
  AccordionsBasic,
  AccordionsBorderless,
  AccordionsList,
  AccordionsSmall,
  React
};