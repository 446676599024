exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-components-accordions-mdx": () => import("./../../../src/pages/components/accordions.mdx" /* webpackChunkName: "component---src-pages-components-accordions-mdx" */),
  "component---src-pages-components-alerts-mdx": () => import("./../../../src/pages/components/alerts.mdx" /* webpackChunkName: "component---src-pages-components-alerts-mdx" */),
  "component---src-pages-components-badges-mdx": () => import("./../../../src/pages/components/badges.mdx" /* webpackChunkName: "component---src-pages-components-badges-mdx" */),
  "component---src-pages-components-breadcrumbs-mdx": () => import("./../../../src/pages/components/breadcrumbs.mdx" /* webpackChunkName: "component---src-pages-components-breadcrumbs-mdx" */),
  "component---src-pages-components-buttons-mdx": () => import("./../../../src/pages/components/buttons.mdx" /* webpackChunkName: "component---src-pages-components-buttons-mdx" */),
  "component---src-pages-components-cards-mdx": () => import("./../../../src/pages/components/cards.mdx" /* webpackChunkName: "component---src-pages-components-cards-mdx" */),
  "component---src-pages-components-checkboxes-mdx": () => import("./../../../src/pages/components/checkboxes.mdx" /* webpackChunkName: "component---src-pages-components-checkboxes-mdx" */),
  "component---src-pages-components-chips-mdx": () => import("./../../../src/pages/components/chips.mdx" /* webpackChunkName: "component---src-pages-components-chips-mdx" */),
  "component---src-pages-components-content-tree-mdx": () => import("./../../../src/pages/components/content-tree.mdx" /* webpackChunkName: "component---src-pages-components-content-tree-mdx" */),
  "component---src-pages-components-dropdowns-mdx": () => import("./../../../src/pages/components/dropdowns.mdx" /* webpackChunkName: "component---src-pages-components-dropdowns-mdx" */),
  "component---src-pages-components-file-upload-dropzone-mdx": () => import("./../../../src/pages/components/file-upload-dropzone.mdx" /* webpackChunkName: "component---src-pages-components-file-upload-dropzone-mdx" */),
  "component---src-pages-components-index-mdx": () => import("./../../../src/pages/components/index.mdx" /* webpackChunkName: "component---src-pages-components-index-mdx" */),
  "component---src-pages-components-inputs-mdx": () => import("./../../../src/pages/components/inputs.mdx" /* webpackChunkName: "component---src-pages-components-inputs-mdx" */),
  "component---src-pages-components-lists-mdx": () => import("./../../../src/pages/components/lists.mdx" /* webpackChunkName: "component---src-pages-components-lists-mdx" */),
  "component---src-pages-components-messages-mdx": () => import("./../../../src/pages/components/messages.mdx" /* webpackChunkName: "component---src-pages-components-messages-mdx" */),
  "component---src-pages-components-modals-mdx": () => import("./../../../src/pages/components/modals.mdx" /* webpackChunkName: "component---src-pages-components-modals-mdx" */),
  "component---src-pages-components-navbar-mdx": () => import("./../../../src/pages/components/navbar.mdx" /* webpackChunkName: "component---src-pages-components-navbar-mdx" */),
  "component---src-pages-components-pagination-mdx": () => import("./../../../src/pages/components/pagination.mdx" /* webpackChunkName: "component---src-pages-components-pagination-mdx" */),
  "component---src-pages-components-progress-bars-mdx": () => import("./../../../src/pages/components/progress-bars.mdx" /* webpackChunkName: "component---src-pages-components-progress-bars-mdx" */),
  "component---src-pages-components-radio-buttons-mdx": () => import("./../../../src/pages/components/radio-buttons.mdx" /* webpackChunkName: "component---src-pages-components-radio-buttons-mdx" */),
  "component---src-pages-components-sliders-mdx": () => import("./../../../src/pages/components/sliders.mdx" /* webpackChunkName: "component---src-pages-components-sliders-mdx" */),
  "component---src-pages-components-spinners-mdx": () => import("./../../../src/pages/components/spinners.mdx" /* webpackChunkName: "component---src-pages-components-spinners-mdx" */),
  "component---src-pages-components-switches-mdx": () => import("./../../../src/pages/components/switches.mdx" /* webpackChunkName: "component---src-pages-components-switches-mdx" */),
  "component---src-pages-components-tables-mdx": () => import("./../../../src/pages/components/tables.mdx" /* webpackChunkName: "component---src-pages-components-tables-mdx" */),
  "component---src-pages-components-tabs-mdx": () => import("./../../../src/pages/components/tabs.mdx" /* webpackChunkName: "component---src-pages-components-tabs-mdx" */),
  "component---src-pages-components-toasts-mdx": () => import("./../../../src/pages/components/toasts.mdx" /* webpackChunkName: "component---src-pages-components-toasts-mdx" */),
  "component---src-pages-components-tooltips-mdx": () => import("./../../../src/pages/components/tooltips.mdx" /* webpackChunkName: "component---src-pages-components-tooltips-mdx" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-foundations-color-palette-mdx": () => import("./../../../src/pages/foundations/color-palette.mdx" /* webpackChunkName: "component---src-pages-foundations-color-palette-mdx" */),
  "component---src-pages-foundations-grid-and-spacing-mdx": () => import("./../../../src/pages/foundations/grid-and-spacing.mdx" /* webpackChunkName: "component---src-pages-foundations-grid-and-spacing-mdx" */),
  "component---src-pages-foundations-shadows-and-depth-mdx": () => import("./../../../src/pages/foundations/shadows-and-depth.mdx" /* webpackChunkName: "component---src-pages-foundations-shadows-and-depth-mdx" */),
  "component---src-pages-foundations-typography-mdx": () => import("./../../../src/pages/foundations/typography.mdx" /* webpackChunkName: "component---src-pages-foundations-typography-mdx" */),
  "component---src-pages-getting-started-changelog-mdx": () => import("./../../../src/pages/getting-started/changelog.mdx" /* webpackChunkName: "component---src-pages-getting-started-changelog-mdx" */),
  "component---src-pages-getting-started-index-mdx": () => import("./../../../src/pages/getting-started/index.mdx" /* webpackChunkName: "component---src-pages-getting-started-index-mdx" */),
  "component---src-pages-getting-started-usage-mdx": () => import("./../../../src/pages/getting-started/usage.mdx" /* webpackChunkName: "component---src-pages-getting-started-usage-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-layout-index-mdx": () => import("./../../../src/pages/layout/index.mdx" /* webpackChunkName: "component---src-pages-layout-index-mdx" */),
  "component---src-pages-utilities-borders-mdx": () => import("./../../../src/pages/utilities/borders.mdx" /* webpackChunkName: "component---src-pages-utilities-borders-mdx" */),
  "component---src-pages-utilities-clearfix-mdx": () => import("./../../../src/pages/utilities/clearfix.mdx" /* webpackChunkName: "component---src-pages-utilities-clearfix-mdx" */),
  "component---src-pages-utilities-close-icon-mdx": () => import("./../../../src/pages/utilities/close-icon.mdx" /* webpackChunkName: "component---src-pages-utilities-close-icon-mdx" */),
  "component---src-pages-utilities-display-mdx": () => import("./../../../src/pages/utilities/display.mdx" /* webpackChunkName: "component---src-pages-utilities-display-mdx" */),
  "component---src-pages-utilities-embed-mdx": () => import("./../../../src/pages/utilities/embed.mdx" /* webpackChunkName: "component---src-pages-utilities-embed-mdx" */),
  "component---src-pages-utilities-flex-mdx": () => import("./../../../src/pages/utilities/flex.mdx" /* webpackChunkName: "component---src-pages-utilities-flex-mdx" */),
  "component---src-pages-utilities-float-mdx": () => import("./../../../src/pages/utilities/float.mdx" /* webpackChunkName: "component---src-pages-utilities-float-mdx" */),
  "component---src-pages-utilities-interactions-mdx": () => import("./../../../src/pages/utilities/interactions.mdx" /* webpackChunkName: "component---src-pages-utilities-interactions-mdx" */),
  "component---src-pages-utilities-overflow-mdx": () => import("./../../../src/pages/utilities/overflow.mdx" /* webpackChunkName: "component---src-pages-utilities-overflow-mdx" */),
  "component---src-pages-utilities-padding-and-margin-mdx": () => import("./../../../src/pages/utilities/padding-and-margin.mdx" /* webpackChunkName: "component---src-pages-utilities-padding-and-margin-mdx" */),
  "component---src-pages-utilities-position-mdx": () => import("./../../../src/pages/utilities/position.mdx" /* webpackChunkName: "component---src-pages-utilities-position-mdx" */),
  "component---src-pages-utilities-screen-readers-mdx": () => import("./../../../src/pages/utilities/screen-readers.mdx" /* webpackChunkName: "component---src-pages-utilities-screen-readers-mdx" */),
  "component---src-pages-utilities-sizing-mdx": () => import("./../../../src/pages/utilities/sizing.mdx" /* webpackChunkName: "component---src-pages-utilities-sizing-mdx" */),
  "component---src-pages-utilities-text-mdx": () => import("./../../../src/pages/utilities/text.mdx" /* webpackChunkName: "component---src-pages-utilities-text-mdx" */),
  "component---src-pages-utilities-vertical-alignment-mdx": () => import("./../../../src/pages/utilities/vertical-alignment.mdx" /* webpackChunkName: "component---src-pages-utilities-vertical-alignment-mdx" */),
  "component---src-pages-utilities-visibility-mdx": () => import("./../../../src/pages/utilities/visibility.mdx" /* webpackChunkName: "component---src-pages-utilities-visibility-mdx" */)
}

