import { graphql } from "gatsby";
import { AlertsBasic, AlertsWithLink, AlertsWithButton, AlertsDismissible } from "../../../../src/examples/components/Alerts";
import * as React from 'react';
export default {
  graphql,
  AlertsBasic,
  AlertsWithLink,
  AlertsWithButton,
  AlertsDismissible,
  React
};