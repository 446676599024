import { graphql } from 'gatsby';
import { NavbarBasic, NavbarBlue, NavbarWithoutHamburger } from "../../../../src/examples/components/Navbar";
import TrimbleLogo from "../../../../src/assets/img/trimble-logo.svg";
import TrimbleIcon from "../../../../src/assets/img/trimble-icon.svg";
import TrimbleLogoRev from "../../../../src/assets/img/trimble-logo-rev.svg";
import TrimbleIconRev from "../../../../src/assets/img/trimble-icon-rev.svg";
import * as React from 'react';
export default {
  graphql,
  NavbarBasic,
  NavbarBlue,
  NavbarWithoutHamburger,
  TrimbleLogo,
  TrimbleIcon,
  TrimbleLogoRev,
  TrimbleIconRev,
  React
};