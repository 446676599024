import { graphql } from 'gatsby';
import { TableBasic, TableHoverable, TableIconsControls, TableSmall, TableWithSorting, MakeData as makeData, TableStyled, DataTableWithSorting, DataTableWithCustomSorting, DataTableWithScroll, DataTableWithoutPagination, DataTableWithColumnResize, DataTableWithSingleRowSelection, DataTableWithMultiRowSelection, DataTableWithCheckBoxSelection, DataTableWithCustomCheckBoxSelection, DataTableWithStickyFirstColumn, DataTableWithColumnFilter, Editable, DataTableWithCellEditable, DataTableWithGlobalFilter, DataTableWithDragAndDrop, DataTableColumnAPIInfo, DataTableColumnInstanceAPIInfo } from "../../../../src/examples/components/Table";
import Table from "../../../../../src/Table";
import * as React from 'react';
export default {
  graphql,
  TableBasic,
  TableHoverable,
  TableIconsControls,
  TableSmall,
  TableWithSorting,
  makeData,
  TableStyled,
  DataTableWithSorting,
  DataTableWithCustomSorting,
  DataTableWithScroll,
  DataTableWithoutPagination,
  DataTableWithColumnResize,
  DataTableWithSingleRowSelection,
  DataTableWithMultiRowSelection,
  DataTableWithCheckBoxSelection,
  DataTableWithCustomCheckBoxSelection,
  DataTableWithStickyFirstColumn,
  DataTableWithColumnFilter,
  Editable,
  DataTableWithCellEditable,
  DataTableWithGlobalFilter,
  DataTableWithDragAndDrop,
  DataTableColumnAPIInfo,
  DataTableColumnInstanceAPIInfo,
  Table,
  React
};