import { graphql } from "gatsby";
import { BadgesRegular, BadgesText, BadgesCounter, BadgesInOtherElements, BadgesSizes } from "../../../../src/examples/components/Badges";
import * as React from 'react';
export default {
  graphql,
  BadgesRegular,
  BadgesText,
  BadgesCounter,
  BadgesInOtherElements,
  BadgesSizes,
  React
};