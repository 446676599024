import { graphql } from "gatsby";
import { ChipsFilter, ChipsInput, ChipsLiveExample, ChipsSmall } from "../../../../src/examples/components/Chips";
import chipImage from "../../../../src/assets/img/headshot.png"; // relative path to image
import * as React from 'react';
export default {
  graphql,
  ChipsFilter,
  ChipsInput,
  ChipsLiveExample,
  ChipsSmall,
  chipImage,
  React
};