import { graphql } from "gatsby";
import { CardsBasic, CardsWithImage, CardsCustomize, CardsHeaderAndFooter } from "../../../../src/examples/components/Cards";
import * as React from 'react';
export default {
  graphql,
  CardsBasic,
  CardsWithImage,
  CardsCustomize,
  CardsHeaderAndFooter,
  React
};