export const ClearfixBasic = `
<div class="bg-light clearfix">
  <button class="btn btn-secondary float-left">
    Example button floated left
  </button>
  <button class="btn btn-secondary float-right">
    Example button floated right
  </button>
</div>
`
