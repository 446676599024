import CodeHighlight from "../../../../src/common/CodeHighlight";
import { SizingBasic, SizingBasicMore, SizingSvg, SizingSvgMore, SizingViewPort } from "../../../../src/examples/utilities/Sizing";
import * as React from 'react';
export default {
  CodeHighlight,
  SizingBasic,
  SizingBasicMore,
  SizingSvg,
  SizingSvgMore,
  SizingViewPort,
  React
};